<!-- 首页 -->
<template>
  <div class="main-page">
    <div class="page-top-area clr">
      <div class="page-top-left-area fl">
        <div class="page-title">👏 欢迎回来，{{userInfo.nickName}}</div>
      </div>
      <div class="page-top-right-area fl"></div>
    </div>
    <div class="img-box">
      <img style="width:80%" src="@/assets/images/login-back-ground_accessories.png" />
    </div>
    <div class="page-btm-area clr">
      <div class="page-btm-right-area fl"></div>
      <div class="page-btm-right-area fl"></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      userInfo: this.$store.getters.userInfo
    };
  },
  mounted() { },
  methods: {}
}

</script>
<style lang='scss' scoped>
.main-page {
  height: calc(100vh - 150px);
  .img-box {
    overflow: hidden;
    text-align: center;
    height: calc(100% - 40px);
  }
  .page-title {
    color: #1d2129;
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 20px;
  }
}
</style>